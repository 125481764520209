"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntryPointNotificationTypesEnum = exports.ApplicationAuthenticationStatusesEnum = void 0;
var entryPointTypesV2_1 = require("./entryPointTypesV2");
var ApplicationAuthenticationStatusesEnum;
(function (ApplicationAuthenticationStatusesEnum) {
    ApplicationAuthenticationStatusesEnum["AUTHORIZED"] = "authorized";
})(ApplicationAuthenticationStatusesEnum || (exports.ApplicationAuthenticationStatusesEnum = ApplicationAuthenticationStatusesEnum = {}));
var EntryPointNotificationTypesEnum;
(function (EntryPointNotificationTypesEnum) {
    EntryPointNotificationTypesEnum["INCOMING_CALL"] = "INCOMING_CALL";
})(EntryPointNotificationTypesEnum || (exports.EntryPointNotificationTypesEnum = EntryPointNotificationTypesEnum = {}));
