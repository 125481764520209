"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HttpVerbsEnum = void 0;
var HttpVerbsEnum;
(function (HttpVerbsEnum) {
    HttpVerbsEnum["GET"] = "GET";
    HttpVerbsEnum["POST"] = "POST";
    HttpVerbsEnum["PUT"] = "PUT";
    HttpVerbsEnum["PATCH"] = "PATCH";
    HttpVerbsEnum["DELETE"] = "DELETE";
})(HttpVerbsEnum || (exports.HttpVerbsEnum = HttpVerbsEnum = {}));
