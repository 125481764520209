"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBridge = void 0;
function getBridge() {
    // eslint-disable-next-line no-undef
    return window.buildBridge('2.0.0');
}
exports.getBridge = getBridge;
/*
 * Re-export types.
 */
__exportStar(require("./internal/asyncTypesV2"), exports);
__exportStar(require("./internal/attachmentTypesV2"), exports);
__exportStar(require("./internal/channelTypesV2"), exports);
__exportStar(require("./internal/commentTypesV2"), exports);
__exportStar(require("./internal/contactTypesV2"), exports);
__exportStar(require("./internal/contextTypesV2"), exports);
__exportStar(require("./internal/conversationTypesV2"), exports);
__exportStar(require("./internal/draftTypesV2"), exports);
__exportStar(require("./internal/entryPointTypesV2"), exports);
__exportStar(require("./internal/errorTypesV2"), exports);
__exportStar(require("./internal/httpTypesV2"), exports);
__exportStar(require("./internal/idTypesV2"), exports);
__exportStar(require("./internal/inboxTypesV2"), exports);
__exportStar(require("./internal/linkTypesV2"), exports);
__exportStar(require("./internal/messageTypesV2"), exports);
__exportStar(require("./internal/paginationTypesV2"), exports);
__exportStar(require("./internal/recipientTypesV2"), exports);
__exportStar(require("./internal/tagTypesV2"), exports);
__exportStar(require("./internal/teammateTypesV2"), exports);
__exportStar(require("./internal/topicTypesV2"), exports);
__exportStar(require("./internal/widgetTypesV2"), exports);
